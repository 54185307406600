/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layingout';
import MapsVideo from '../assets/videos/vv-v1-2.mp4';
import GridBlock from '../components/gridBlockExternal';
import TopMenu from '../components/topMenu';
import Swal from 'sweetalert2'


const IndexPage = () => {

(async () => {

})() 
  const data = useStaticQuery(graphql`
    query WhyHelpTwoImg {
      transition: file(relativePath: { eq: "logo-slide-2.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      directions: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      procedures: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      practice: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appointment: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aestheticians: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doctors: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      injection: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      beforeAfter: file(relativePath: { eq: "stone8.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    
    const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex               : '99',
  }
};
    
 function closeModal(){
    setIsOpen(false);
  }    
    
  const blockCSS = 'flex flex-auto group w-full relative h-1/8 border-0';
  const colorCSS =
    'transition h-full w-full absolute opacity-10 group-hover:opacity-30';
  return (
    <Layout to="/" label="BACK TO HOME" showMenuIcon className="bg-black ">
                                      <TopMenu></TopMenu>

      <section className="h-screen w-screen">
        <div className="absolute w-screen h-screen">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="object-cover h-full w-full absolute"
          >
            <source src={MapsVideo} type="video/mp4" />
          </video>
          <div className="w-full h-full overlay absolute"></div>
        </div>
      <div className="relative flex h-screen w-screen justify-center flex-col">
        <div className="flex flex-wrap justify-center">
      
      
      
 

    

    
      
      <a target="_blank" class="flex flex-auto group w-full relative h-1/8 border-0" href="https:/marvelapp.com/prototype/4j75dfa/screen/71397029">
      <div class="object-cover w-full h-full absolute gatsby-image-wrapper"></div>
      <div class="transition h-full w-full absolute opacity-10 group-hover:opacity-30 bg-white group-hover:bg-black"></div>
      <div class="h-full w-full absolute flex items-center justify-center"><h3 class="transition font-bold vivo text-white text-center md:mt-6 group-hover:mt-0 md:text-xl mobile-fix">Wire Frame Desktop View</h3></div></a>
      
      <a target="_blank" class="flex flex-auto group w-full relative h-1/8 border-0" href="https://marvelapp.com/prototype/eijfc17/screen/71613743">
      <div class="object-cover w-full h-full absolute gatsby-image-wrapper"></div>
      <div class="transition h-full w-full absolute opacity-10 group-hover:opacity-30 bg-white group-hover:bg-black"></div>
      <div class="h-full w-full absolute flex items-center justify-center"><h3 class="transition font-bold vivo text-white text-center md:mt-6 group-hover:mt-0 md:text-xl mobile-fix">Wire Frame iPpad View</h3></div></a>
      <a target="_blank" class="flex flex-auto group w-full relative h-1/8 border-0" href="https:/marvelapp.com/prototype/4j75dfa/screen/7139702">
      <div class="object-cover w-full h-full absolute gatsby-image-wrapper"></div>
      <div class="transition h-full w-full absolute opacity-10 group-hover:opacity-30 bg-white group-hover:bg-black"></div>
      <div class="h-full w-full absolute flex items-center justify-center"><h3 class="transition font-bold vivo text-white text-center md:mt-6 group-hover:mt-0 md:text-xl mobile-fix">Wire Frame Desktop View</h3></div></a>

        </div>
</div>
      </section>
    </Layout>
  );
};

export default IndexPage;
